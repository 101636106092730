import React, { useState } from "react";
import { helpCardData } from "../../mockData";
import Navigation from "../../components/Navigation";

const Help = () => {
  const [page, setPage] = useState(false);
  return (
    <>
      <div className="w-full h-screen  overflow-auto max-w-[2050px] p-5 sm:pb-[120px] m-auto">
        <div className="w-full sm:w-[90%] m-auto">
          <img src="/images/help-hero-img.png" className="w-full" alt="img" />
        </div>

        <div
          className="flex flex-col w-full sm:w-[90%] bg-white rounded-[12px] m-auto px-3 sm:px-5 mb-5 sm:mb-10"
          onClick={() => setPage(true)}
        >
          <div className="flex justify-between items-center py-[12px] sm:py-10 cursor-pointer">
            <div className="flex gap-4 items-center">
              <img
                src="/images/online-help-img.svg"
                alt="img"
                className="w-[27px] sm:w-[52px] h-[27px] sm:h-[52px]"
              />
              <h3 className="text-[14px] sm:text-[28px] font-medium text-[#3e536c] ">
                online help
              </h3>
            </div>

            <img
              src={"/icons/right-arrow.svg"}
              alt="img"
              className="w-3 sm:w-6 h-3 sm:h-6 rounded-[50%] flex justify-center items-center "
            />
          </div>
        </div>
        <div className="w-full sm:w-[90%] h-auto m-auto rounded-[12px] p-4 sm:p-8 mb-4 sm:mb-8 bg-white">
          {helpCardData.map((item, index) => {
            return (
              <div className="w-full">
                <div className="flex items-center gap-5 my-3 sm:my-6">
                  <img
                    src={item.img}
                    alt="img"
                    className="w-5 sm:w-10 h-5 sm:h-10"
                  />
                  <h3 className="text-[14px] sm:text-[28px] text-[rgba(0_,0_,0_,0.8)] font-bold">
                    {item.title}
                  </h3>
                </div>
                <p
                  className={`text-[14px] sm:text-[28px]  text-[rgba(0_,0_,0_,0.5)] pb-[30px] ${
                    index === 11 ? "border-none" : "border-b"
                  }  border-b-[rgba(67,_67,_67,_.07)]`}
                >
                  {item.description}
                </p>
              </div>
            );
          })}
        </div>
        {page && (
          <>
            {" "}
            <div
              className={`w-full h-screen fixed top-0 right-0 bg-white  ${
                page ? "helpPageOnAnimation" : "helpPageOffAnimation"
              } `}
            >
              {/* navbar ---  */}
              <div className="w-full h-[100px] flex justify-between items-center px-8">
                <img
                  src="/icons/left-arrow.svg"
                  alt="img"
                  onClick={() => setPage(false)}
                  className="cursor-pointer"
                />
                <h3 className="text-[36px] text-[#131f30]">OnlineHelp</h3>
                <span></span>
              </div>
              <iframe
                title="OnlineHelp"
                src="https://direct.lc.chat/17490696/"
                className="w-full h-[calc(100vh-100px)]"
              />
            </div>
          </>
        )}
      </div>
      {page ? null : <Navigation />}
    </>
  );
};

export default Help;
