import React, { useEffect, useState } from "react";
import ETHSection from "../../components/Navigation/ETHSection";
import TransactionSection from "../../components/TransactionSection";
import DropdownSection from "../../components/DropdownSection";
import { columns, data } from "../../mockData";
import SwapCard from "../../components/SwapCard";
import Navigation from "../../components/Navigation";
import axios from "axios";

const sponserLogos = [
  {
    img: "/images/logo-1.png",
  },
  {
    img: "/images/logo-2.png",
  },
  {
    img: "/images/logo-3.png",
  },
  {
    img: "/images/logo-4.png",
  },
  {
    img: "/images/logo-5.png",
  },
  {
    img: "/images/logo-6.png",
  },
  {
    img: "/images/logo-7.png",
  },
  {
    img: "/images/logo-8.png",
  },
  {
    img: "/images/logo-9.png",
  },
];

const Home = ({ walletAddress }) => {
  const [volume, setVolume] = useState(null);
  const [volume1, setVolume1] = useState(null);
  const [volume2, setVolume2] = useState(null);
  const [volume3, setVolume3] = useState(null);
  const [volume4, setVolume4] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/ticker/24hr"
        );
        const ethPair = response.data.find((pair) => pair.symbol === "ETHUSDT");
        const volume = parseFloat(ethPair.weightedAvgPrice);
        setVolume(parseFloat(volume).toFixed(8));
        const bscPair = response.data.find((pair) => pair.symbol === "BNBUSDT");
        const volume1 = parseFloat(bscPair.weightedAvgPrice);
        setVolume1(parseFloat(volume1).toFixed(8));
        const btcPair = response.data.find((pair) => pair.symbol === "BTCUSDT");
        const volume2 = parseFloat(btcPair.weightedAvgPrice);
        setVolume2(parseFloat(volume2).toFixed(8));
        const dotPair = response.data.find((pair) => pair.symbol === "DOTUSDT");
        const volume3 = parseFloat(dotPair.weightedAvgPrice);
        setVolume3(parseFloat(volume3).toFixed(8));
        const solPair = response.data.find((pair) => pair.symbol === "SOLUSDT");
        const volume4 = parseFloat(solPair.weightedAvgPrice);
        setVolume4(parseFloat(volume4).toFixed(8));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="w-full h-screen overflow-auto max-w-[2050px] p-5 sm:pb-[120px] m-auto">
        <div>
          <img src="/images/home-img.png" className="w-full h-full" alt="img" />
        </div>

        <ETHSection walletAddress={walletAddress} />
        <TransactionSection walletAddress={walletAddress} />
        <DropdownSection
          currencyIcon={"/images/binance-img.png"}
          currencyName={"binance"}
          amount={volume}
          tableColumns={columns}
          tableData={data}
        />
        <DropdownSection
          currencyIcon={"/images/huobi-img.png"}
          currencyName={"huobi"}
          amount={volume1}
          tableColumns={columns}
          tableData={data}
        />
        <DropdownSection
          currencyIcon={"/images/gate-img.png"}
          currencyName={"gate"}
          amount={volume2}
          tableColumns={columns}
          tableData={data}
        />
        <DropdownSection
          currencyIcon={"/images/okex-img.png"}
          currencyName={"okex"}
          amount={volume3}
          tableColumns={columns}
          tableData={data}
        />
        <DropdownSection
          currencyIcon={"/images/kucoin-img.png"}
          currencyName={"kucoin"}
          amount={volume}
          tableColumns={columns}
          tableData={data}
        />
        <DropdownSection
          currencyIcon={"/images/kraken-img.png"}
          currencyName={"kraken"}
          amount={volume4}
          tableColumns={columns}
          tableData={data}
        />
        <div className="w-full sm:w-[90%] m-auto grid grid-cols-3 justify-items-center place-items-center gap-[30px] sm:gap-[60px] pt-[50px] mb-[25px] sm:mb-[50px]">
          <SwapCard
            image={"/images/swap-img-1.png"}
            title={"1 Inch"}
            link={"https://app.1inch.io/#/1/simple/swap/ETH"}
          />
          <SwapCard
            image={"/images/uniswap.png"}
            title={"UniSwap"}
            link={"https://app.uniswap.org/"}
          />
          <SwapCard
            image={"/images/sushi-swap-img.png"}
            title={"SushiSwap"}
            link={
              "https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x6B3595068778DD592e39A122f4f5a5cF09C90fE2"
            }
          />
        </div>
        <div className="w-full sm:w-[90%] m-auto grid grid-cols-3 gap-5 justify-items-center place-items-center sm:gap-10">
          {sponserLogos.map((item, index) => {
            return (
              <img src={item.img} alt="img" key={index} className="w-[170px]" />
            );
          })}
        </div>
        <p className="w-[90%] text-center text-[24px] text-[rgba(0,_0,_0,_0.5)] m-auto my-10">
          it's over
        </p>
      </div>
      <Navigation />
    </>
  );
};

export default Home;
