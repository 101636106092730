import React, { useState } from "react";
import DataTable from "react-data-table-component";

const DropdownSection = ({
  currencyIcon,
  currencyName,
  amount,
  tableColumns,
  tableData,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div className="flex flex-col w-full sm:w-[90%] bg-white rounded-[12px] m-auto px-5 mb-4">
      <div
        className="flex justify-between items-center pt-[18px] sm:pt-[36px] pb-[14px] sm:pb-[28px] cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <div className="flex gap-4 items-center">
          <img
            src={currencyIcon}
            alt="img"
            className="w-[28px] sm:w-[56px] h-[28px] sm:h-[56px]"
          />
          <h3 className="text-[14px] sm:text-[28px] font-medium text-[#3e536c] ">
            {currencyName}
          </h3>
        </div>
        <div className="flex gap-4 items-center">
          <h3 className="text-[14px] sm:text-[28px] font-medium text-[#3e536c] ">
            {amount}
          </h3>
          <img
            src={active ? "/icons/up-arrow.svg" : "/icons/down-arrow.svg"}
            alt="img"
            className="w-[20px] sm:w-10 h-[20px] sm:h-10 rounded-[50%] flex justify-center items-center "
          />
        </div>
      </div>
      {active && <DataTable columns={tableColumns} data={tableData} />}
    </div>
  );
};

export default DropdownSection;
