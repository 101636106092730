import React from "react";

const mapData = [
  {
    title: "limit(USDT)",
  },
  {
    title: "Rate of return",
  },
  {
    title: "Cycle",
  },
];

const StrategicCard = ({
  img,
  title,
  purchaseLimit,
  USDTLimit,
  rateOfReturn,
  cycle,
  imagesData,
  onClick,
  buttonText,
}) => {
  return (
    <div className="w-full h-auto p-[15px] sm:p-6 bg-white rounded-[12px]">
      <div className="w-full flex flex-col xs:flex-row gap-[10px] justify-between items-center pb-6 border-b border-b-[#f0f3ff]">
        <div className="flex items-center gap-[15px] sm:gap-4">
          <img
            src={img}
            alt="img"
            className="w-[36px] sm:w-[72px] h-[36px] sm:h-[72px]"
          />
          <h3 className="text-[18px] sm:text-[40px] font-medium #081724">
            {title}
          </h3>
        </div>
        <p className="text-[16px] sm:text-[28px] text-[#6d87a8]">
          Limited to{" "}
          <span className="text-[16px] sm:text-[30px] text-[#4b69fe] font-medium ">
            {purchaseLimit}
          </span>{" "}
          purchase
        </p>
      </div>
      <div className="w-full mt-6 flex gap-5 justify-between items-center">
        {mapData.map((item, index) => {
          return (
            <div key={index}>
              <p className="whitespace-nowrap text-[13px] sm:text-[26px] text-[#6d87a8]">
                {item.title}
              </p>
              <p className="whitespace-nowrap text-[14px] sm:text-[28px] text-[#131f30] mt-1">
                {index === 0 ? USDTLimit : index === 1 ? rateOfReturn : cycle}
                {index === 1 ? "%" : index === 2 ? "Day" : null}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-3 sm:mt-6 pl-6 w-full h-[68px] flex items-center gap-5 sm:gap-10 rounded-[12px] bg-[#f5f7fb] overflow-auto">
        {imagesData.map((item, index) => {
          return (
            <img
              src={item}
              alt="img"
              key={index}
              className="w-5 sm:w-10 h-5 sm:h-10"
            />
          );
        })}
      </div>
      <button
        type="button"
        className="w-full h-[48px] sm:h-[66px] mt-[15px] sm:mt-[30px] bg-[#3875f6] rounded-[12px] text-[16px] sm:text-[36px] text-white flex justify-center items-center "
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default StrategicCard;
