import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Help from "./pages/Help";
import Pledge from "./pages/Pledge";
import Share from "./pages/Share";
import Account from "./pages/Account";
import Web3 from "web3";
import HomeModal from "./components/HomeModal";

function App() {
  const [web3, setWeb3] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);

  // useEffect(() => {
  //   const checkWeb3 = async () => {
  //     if (window.ethereum) {
  //       const web3Instance = new Web3(window.ethereum);
  //       setWeb3(web3Instance);
  //       try {
  //         // Request account access if needed
  //         const accounts = await window.ethereum.request({
  //           method: "eth_requestAccounts",
  //         });
  //         setWalletConnected(true);
  //         setWalletAddress(accounts[0]);
  //       } catch (error) {
  //         console.error("User denied account access");
  //       }
  //     } else {
  //       console.log("Web3 not found");
  //     }
  //   };

  //   checkWeb3();
  // }, []);
  useEffect(() => {
    const checkWeb3 = async () => {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.BinanceChain) {
        provider = window.BinanceChain;
      } else if (window.trust) {
        provider = window.trust;
      } else if (window.defi) {
        provider = window.defi;
      } else if (window.OKEx) {
        provider = window.OKEx;
      } else if (window.coinbase) {
        provider = window.coinbase;
      }
      
      if (provider) {
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        try {
          // Request account access if needed
          let accounts;
          if (provider === window.ethereum || provider === window.BinanceChain) {
            accounts = await provider.request({
              method: "eth_requestAccounts"
            });
          } else if (provider === window.trust) {
            accounts = await provider.eth.getAccounts();
          } else if (provider === window.defi) {
            accounts = await provider.ethereum.request({
              method: "eth_accounts"
            });
          } else if (provider === window.OKEx) {
            accounts = await provider.request({
              method: "eth_accounts"
            });
          } else if (provider === window.coinbase) {
            accounts = await provider.request({
              method: "eth_requestAccounts"
            });
          }
          setWalletConnected(true);
          setWalletAddress(accounts[0]);
        } catch (error) {
          console.error("User denied account access");
        }
      } else {
        console.log("Web3 not found");
      }
    };

    checkWeb3();
  }, []);


  return (
    <>
      <BrowserRouter>
        <Routes>
          {walletConnected ? (
            <>
              <Route
                path="/"
                element={<Home walletAddress={walletAddress} />}
              />
              <Route path="/help" element={<Help />} />
              <Route path="/pledge" element={<Pledge walletAddress={walletAddress}/>} />
              <Route path="/share" element={<Share />} />
              <Route path="/account" element={<Account />} />
            </>
          ) : web3 ? (
            ""
          ) : (
            <Route path="/" element={<HomeModal />} />
          )}
        </Routes>
      </BrowserRouter>{" "}
    </>
  );
}

export default App;
