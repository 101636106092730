import React, { useState } from "react";
import Navigation from "../../components/Navigation";

const Share = () => {
  const [link] = useState("https://usdtarbitrage.us/#/?tid=8137534");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (err) {
      console.error("Failed to copy:", err);
      alert("Failed to copy link to clipboard!");
    }
  };
  return (
    <>
      <div className="w-full h-screen pb-[120px] overflow-auto max-w-[2050px] m-auto py-5 sm:pt-10 px-5 sm:px-8">
        <div>
          <img
            src="/images/share-hero-img.png"
            className="w-full h-full"
            alt="img"
          />
        </div>
        <div className="w-full sm:w-[90%] h-auto bg-white rounded-[12px] m-auto py-[25px] sm:py-[50px] px-[16px] sm:px-[35px] ">
          <div className="w-full py-6 sm:py-12 px-4 sm:px-8 rounded-[12px] bg-[#f5f7fb] mb-5 sm:mb-10">
            <div className="flex flex-col gap-2 items-center">
              <p className="text-[12px] sm:text-[24px] text-[rgba(17,_48,_99,_.4)]">
                Referral reward (USDT)
              </p>
              <p className="text-[20px] sm:text-[40px] text-black">0.000000</p>
            </div>
            <div className="w-full flex gap-5 justify-around items-center mt-5 sm:mt-10  ">
              <div className="flex flex-col items-center gap-2">
                <div className="flex gap-2 items-center">
                  <img
                    src="/icons/direct-icon.svg"
                    alt="img"
                    className="w-4 sm:w-auto h-4 sm:h-auto"
                  />
                  <p className="text-[14px] sm:text-[28px] text-[rgba(17,-48,_99,_.4)] ">
                    Direct
                  </p>
                </div>
                <p className="text-[16px] sm:text-[32px]">0</p>
              </div>
              <div className="flex flex-col items-center gap-2">
                <div className="flex gap-2 items-center">
                  <img
                    src="/icons/indirect-icon.svg"
                    alt="img"
                    className="w-4 sm:w-auto h-4 sm:h-auto"
                  />
                  <p className="text-[14px] sm:text-[28px] text-[rgba(17,-48,_99,_.4)] ">
                    Indirect
                  </p>
                </div>
                <p className="text-[16px] sm:text-[32px]">0</p>
              </div>
            </div>
          </div>
          <div className="py-4 sm:py-8 w-full flex flex-col gap-2 items-start sm:items-center border-t border-t-[#eceff6]">
            <h3 className="text-[16px] sm:text-[32px] font-bold">
              Get referral <span className="text-[#4b69fe]">20</span> USDT
              rewards
            </h3>
            <p className="text-[12px] sm:text-[24px] text-[rgba(0,_0,_0,.5)]">
              Invite friends to use Ai quantitative trading, you can get 20USDT
              reward
            </p>
          </div>
          <div className="w-full h-[50px] sm:h-[89px] bg-[#f9fafb] rounded-[12px] border flex justify-center items-center relative">
            <p className="text-[12px] sm:text-[26px] px-5 ">{link}</p>
            <button
              type="button"
              className="hidden sm:block w-[130px] h-[56px] rounded-[29px] bg-[#e9edef] text-[28px] text-[#4e4b66] absolute right-[20px]"
              onClick={copyToClipboard}
            >
              Copy
            </button>
          </div>
          {isCopied && (
            <p className="w-full sm:w-[250px] text-center relative top-[10px] m-auto text-[16px] font-medium text-black p-[10px] rounded-[30px] bg-[#f6f6f6] clipBoardTextAnimation ">
              Text copied to clipboard!
            </p>
          )}
          <button
            type="button"
            className="w-full h-[48px] sm:h-[98px] text-[14px] sm:text-[28px] text-white text-center font-medium sm:font-bold mt-[24px] sm:mt-[48px] rounded-[8px] bg-[#3875f6] "
            onClick={copyToClipboard}
          >
            SHARE LINK
          </button>
        </div>
      </div>
      <Navigation />
    </>
  );
};

export default Share;
