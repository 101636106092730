import { useState } from "react";

const HomeModal = () => {
  const [copied, setCopied] = useState(false);
  const copyLink = () => {
    const currentUrl = window.location.href;

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
  };
  return (
    <>
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-start items-center w-[90%] sm:w-[686px] max-h-[90vh] rounded-[12px] bg-[#3f45ff] p-5 sm:p-10 overflow-auto">
        <div>
          <div>
            <img
              src="/images/home-modal-img.png"
              alt="img"
              className="w-[200px] sm:w-[240px] m-auto"
            />
          </div>
          <h3 className="text-[36px] sm:text-[48px] text-white font-medium text-center mt-[30px]">
            Unable to connect
          </h3>
          <p className="text-[24px] sm:text-[30px] text-white font-normal text-center mt-[30px] ">
            This website relies on Ethereum smart contracts to operate, please
            use a decentralized wallet to access.
          </p>

          <button
            type="button"
            className="w-full h-[60px] sm:h-[98px] rounded-[12px] bg-white text-[#4b69fe] mt-[30px] text-[24px] sm:text-[28px] font-medium "
            onClick={copyLink}
          >
            Copy Link
          </button>
        </div>
      </div>
      {copied && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-2 rounded-lg shadow-lg">
          <p className="text-[12px] text-white">Link copied!</p>
        </div>
      )}
    </>
  );
};

export default HomeModal;
