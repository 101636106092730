import React, { useState } from "react";
import Navigation from "../../components/Navigation";

const Account = () => {
  const [value, setValue] = useState("");
  const [page, setPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue >= 0) {
      setValue(inputValue);
    }
  };

  const handleMaxClick = () => {
    setValue(0);
  };
  const handleClick = () => {
    if (!value) {
      setErrorMessage("Please input the correct data");
      setTimeout(() => {
        setErrorMessage("");
      }, 1000);
    } else {
      setErrorMessage("Insufficient available balance.");
      setTimeout(() => {
        setErrorMessage("");
      }, 1000);
    }
  };
  return (
    <>
      <div className="w-full h-screen pb-[120px] p-10 overflow-auto max-w-[2050px] m-auto px-5 sm:px-8">
        <div className="w-full h-auto flex flex-col items-center bg-[#ffeed1] rounded-[12px] p-0 sm:p-8 pt-5 sm:mt-10">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setPage(true)}
          >
            <h3 className="text-[14px] sm:text-[28px] text-[#113063] font-bold">
              RECORD
            </h3>
            <img
              src="/icons/right-arrow.svg"
              alt="img"
              className="w-3 sm:w-auto h-3 sm:h-auto"
            />
          </div>
          <div className="w-full p-[15px] sm:p-6 rounded-[12px] bg-[#fbe6b7] mt-5 sm:mt-8">
            <div className="flex flex-col gap-2">
              <h3 className="text-[12px] sm:text-[24px] text-[rgba(17,_48,_99,_.4)] ">
                Available Balance(USDT)
              </h3>
              <p className="text-[20px] sm:text-[60px] text-[#113063] font-semibold ">
                0.00
              </p>
            </div>
            <div className="mt-4 sm:mt-8 p-5 w-full flex gap-5 justify-evenly items-center rounded-[12px] bg-white">
              <div className="flex flex-col items-center">
                <p className="text-[12px] sm:text-[24px] text-[rgba(17,_48,_99,_.4)] ">
                  Total revenue (USDT)
                </p>
                <h3 className="text-[16px] sm:text-[32px] mt-3 texxt-[#113063] font-semibold">
                  0.000000
                </h3>
              </div>
              <div className="hidden sm:block h-[90px] border border-[#eeeef0]"></div>
              <div className="flex flex-col items-center">
                <p className="text-[12px] sm:text-[24px] text-[rgba(17,_48,_99,_.4)] ">
                  Total revenue (USDT)
                </p>
                <h3 className="text-[16px] sm:text-[32px] mt-3 texxt-[#113063] font-semibold">
                  0.000000
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 mt-5 sm:mt-10 p-4 sm:p-8 rounded-[12px] bg-white ">
          <p className="text-[13px] sm:text-[26px] text-[#3e536c]">Quantity</p>
          <div className="w-full h-auto flex justify-between items-center gap-5 rounded-[12px] border px-[10px] sm:px-10 py-[10px] sm:py-5 box-border">
            <input
              type="number"
              className="w-full h-full text-[22px] sm:text-[56px] text-[#081724] font-bold boder-none outline-none"
              placeholder="0.0"
              value={value}
              onChange={handleChange}
            />
            <button
              type="button"
              className="w-[100px] sm:w-[130px] h-[30px] sm:h-[56px] bg-[#e9edef] rounded-[15px] sm:rounded-[29px] text-[14px] sm:text-[24px] text-[#4083f5] font-medium text-center "
              onClick={handleMaxClick}
            >
              Max
            </button>
          </div>
          <p className="text-[13px] sm:text-[26px] text-[rgba(62,_83,_108,_.6)] mt-4 sm:mt-8 ">
            USDT withdrawal will reach your wallet address within 24 hours after
            you apply for withdrawal. Single minimum withdrawal 10USDT
          </p>
          <button
            type="button"
            className="mt-5 sm:mt-[50px] rounded-[12px] w-full h-[50px] sm:h-[96px] bg-[#53a4f3] text-[18px] sm:text-[28px] text-white font-medium sm:font-bold "
            onClick={handleClick}
          >
            WITHDRAW
          </button>
        </div>
        {errorMessage && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <p className="w-auto p-3 bg-black text-white text-[12px] rounded-[5px]">
              {errorMessage}
            </p>
          </div>
        )}
      </div>
      {page && (
        <div className="fixed right-0 left-0 top-0 bg-white w-full h-screen gradientColor overflow-auto max-w-[2050px] m-auto helpPageOnAnimation">
          <div className="w-full h-[70px] sm:h-[100px] flex justify-between items-center p-[15px] sm:p-8 ">
            <div onClick={() => setPage(false)} className="cursor-pointer">
              <img
                src="/icons/left-arrow.svg"
                alt="img"
                className="w-[30px] sm:w-10 h-[30px] sm:h-10 filter invert"
              />
            </div>
            <p className="text-[20px] sm:text-[36px] text-white">Record</p>
            <span></span>
          </div>
          <div className="w-full h-[calc(100%-100px)] flex justify-center items-center">
            <div className="flex flex-col items-center gap-8">
              <img
                src="/images/no-record-img.png"
                alt="img"
                className="w-[270px] h-[212px] "
              />
              <p className="text-[28px] text-[#c3cbd6]">No record</p>
            </div>
          </div>
        </div>
      )}
      {page ? null : <Navigation />}
    </>
  );
};

export default Account;
