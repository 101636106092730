import React from "react";
import { Link } from "react-router-dom";

const SwapCard = ({ image, title, link }) => {
  return (
    <Link
      to={link}
      className="flex flex-col justify-center items-center w-[106px] sm:w-[212px] h-[86px] sm:h-[172px] p-4 rounded-[16px] cursor-pointer swapCardGradient"
    >
      <img
        src={image}
        alt="img"
        className="w-[51px] sm:w-[102px] h-[51px] sm:h-[102px] mt-[-50px] sm:mt-[-80px] "
      />
      <div className="w-full h-10 sm:h-[80px] bg-white flex justify-center items-center rounded-[8px]">
        <p className="text-[#309bff] text-[14px] sm:text-[24px]">{title}</p>
      </div>
    </Link>
  );
};

export default SwapCard;
