import React from "react";

const ETHSection = ({ walletAddress }) => {
  return (
    <div className="w-full sm:w-[90%] h-auto bg-white p-[0px_10px_10px_10px] sm:p-[0px_20px_20px_20px] m-auto rounded-[12px] overflow-hidden mb-10">
      <div className="w-full h-auto flex gap-5 justify-between items-center pt-5 sm:pt-10 pb-[23px] sm:pb-[46px] ">
        <div>
          <h3 className="text-[#495666] text-[16px] sm:text-[32px] font-bold ">
            Welcome to ETH AI
          </h3>
          <p className="text-[12px]">Artificial intelligence trading</p>
        </div>
        <button
          type="button"
          className="w-[112px] sm:w-[224px] h-[34px] sm:h-[68px] bg-[rgba(158,_219,_182,_.2)] flex justify-center items-center gap-x-3 rounded-[34px] "
        >
          <img
            src="/icons/link-icon.svg"
            alt="img"
            className="w-[14px] sm-w-auto h-[14px] sm:h-auto"
          />
          <span className="text-[14px] sm:text-[28px] text-[rgba(119,_134,_152,_.89)] ">
            {walletAddress.slice(-8)}
          </span>
        </button>
      </div>
      <div className="w-full bg-[#f5f7fb] py-4 sm:py-8 px-[6px] sm:px-3 rounded-[12px] flex gap-5 justify-between items-center">
        <div className="flex flex-col items-center">
          <h3 className="text-[#8995a4] text-[12px] sm:text-[24px]">
            Total earnings (USDT)
          </h3>
          <p className="text-[#142339] text-[20px] sm:text-[40px]">0.000000</p>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-[#8995a4] text-[12px] sm:text-[24px]">
            Earnings 24h (USDT)
          </h3>
          <p className="text-[#142339] text-[20px] sm:text-[40px]">0.000000</p>
        </div>
      </div>
    </div>
  );
};

export default ETHSection;
