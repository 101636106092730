import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import Web3 from "web3";
const TransactionSection = ({ walletAddress }) => {
  const [isAbove640px, setIsAbove640px] = useState(window.innerWidth > 640);
  const [btcVolume, setBtcVolume] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/ticker/24hr"
        );
        const btcPair = response.data.find((pair) => pair.symbol === "BTCUSDT");
        const volume = parseFloat(btcPair.quoteVolume);
        setBtcVolume(parseFloat(volume).toFixed(2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsAbove640px(window.innerWidth > 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const checkEthBalance = async () => {
    const web3 = new Web3(
      "https://mainnet.infura.io/v3/48a9bed6be754ef3b17535a2b3801bd8"
    );
    try {
      // Get the user's ETH balance
      const weiBalance = await web3.eth.getBalance(walletAddress);
      // Convert wei to ETH
      const ethBalance = web3.utils.fromWei(weiBalance, "ether");
      if (parseFloat(ethBalance) >= 0.00001) {
        setMessage("Received");
        closeModal();
        setTimeout(() => {
          setMessage("");
        }, 2000);
      } else {
        setMessage("Your ETH miner fee is insufficient");
        closeModal();
        setTimeout(() => {
          setMessage("");
        }, 2000);
      }
    } catch (error) {
      console.error("Error retrieving balance:", error);
    }
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: `${isAbove640px ? "686px" : "90%"}`,
      height: "90%",
      border: "none",
      padding: `${isAbove640px ? "40px" : "20px"} `,
      borderRadius: "20px",
      overflow: "auto",
      display: "flex",
      justifyContent: "center",
    },
  };
  return (
    <div className="w-full sm:w-[90%] h-auto bg-white p-[10px] sm:p-5 m-auto rounded-[12px] overflow-hidden mb-5 sm:mb-10 transactionSectionGradient">
      <div className="flex justify-between items-start sm:items-center gap-5 mb-5">
        <div>
          <h3 className="text-[16px] sm:text-[32px] font-bold">
            Open transaction!
          </h3>
          <p className="text-[12px] sm:text-[24px] text-[rgba(62,_83,_108,_.7)] max-w-[540px]">
            2000+ base factor library with AI support to more catch derivative
            factors, one step ahead!
          </p>
        </div>
        <div>
          <button
            type="button"
            className="w-9 sm:w-[72px] h-9 sm:h-[72px] rounded-[50%] border-none outline-none flex justify-center items-center bg-white"
            onClick={openModal}
          >
            <img
              src="/icons/plus-button.svg"
              alt="img"
              className="w-5 sm:w-[35px] "
            />
          </button>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="flex flex-col gap-5 items-start">
            <img
              src="/icons/close-modal.png"
              alt="close"
              className="w-[30px] sm:w-10 h-[30px] sm:h-10 absolute top-[20px] sm:top-[50px] right-[20px] sm:right-[50px] cursor-pointer"
              onClick={closeModal}
            />
            <div className="w-full">
              <img
                src="/images/modal-img.png"
                alt="img"
                className="w-auto m-auto"
              />
            </div>
            <h3 className="text-[36px] sm:text-[60px] text-[#142339] font-bold">
              Open Agreement
            </h3>
            <p className="text-[28px] text-[rgba(62,_83,_108,_.8)] ">
              Broadcast the Ai Quantitative Trading Protocol to Nodes
            </p>
            <button
              type="button"
              className="w-full h-[98px] bg-[#3875f6] text-[28px] rounded-[8px] font-bold leading-[98px]"
              onClick={checkEthBalance}
            >
              RECEIVE
            </button>
          </div>
        </Modal>
        {message && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <p className="w-auto p-3 bg-black text-white text-[12px] rounded-[5px]">
              {message}
            </p>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center w-full p-[10px] sm:p-5 border-b border-b-[#eceff6] rounded-[12px_12px_0px_0px] sm:rounded-[20px_20px_0px_0px] bg-white">
        <h3 className="text-[13px] sm:text-[26px] text-[#8995a4]">
          Number of people
        </h3>
        <h3 className="text-[13px] sm:text-[26px] text-[#8995a4]">2584459</h3>
      </div>
      <div className="flex justify-between items-center w-full p-[10px] sm:p-5 border-b border-b-[#eceff6] rounded-[0px_0px_12px_12px] sm:rounded-[0px_0px_20px_20px] bg-white">
        <h3 className="text-[13px] sm:text-[26px] text-[#8995a4]">
          Total revenue
        </h3>
        <h3 className="text-[13px] sm:text-[26px] text-[#8995a4]">
          {btcVolume} USDT
        </h3>
      </div>
    </div>
  );
};

export default TransactionSection;
