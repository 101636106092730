import React, { useEffect, useState } from "react";
import StrategicCard from "../../components/StrategicCard";
import Navigation from "../../components/Navigation";
import ActiveStrategicCard from "../../components/ActiveStrategicCard";
import contractAbi from "../../abi/usdt.json";
import Web3 from "web3";

const cardData = [
  {
    title: "Expected earnings for today",
    amount: "0.00",
  },
  {
    title: "Cumulative income",
    amount: "0.00",
  },
  {
    title: "Order volume",
    amount: "0",
  },
];

const StrategicCards = [
  {
    img: "/images/strategic-img.svg",
    title: " 1.Simas Saham Maksima",
    purchaseLimit: "3",
    income: "Everyday",
    USDTLimit: "500-1000",
    rateOfReturn: "2.21-2.29",
    cycle: "1",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "To Buy",
  },
  {
    img: "/images/strategic-img.svg",
    title: "2.Simas Satu Prima",
    purchaseLimit: "2",
    income: "Everyday",
    USDTLimit: "1000-100000",
    rateOfReturn: "2.58-2.79",
    cycle: "1",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "To Buy",
  },
  {
    img: "/images/strategic-img.svg",
    title: "3.Simas Syariah Berkembang",
    purchaseLimit: "1",
    income: "Everyday",
    USDTLimit: "1000-100000",
    rateOfReturn: "2.81-2.99",
    cycle: "2",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "To Buy",
  },
];
const StrategicStakkingCards = [
  {
    img: "/images/strategic-img.svg",
    title: "4.Simas Danamas Instrumen Negara",
    purchaseLimit: "2",
    income: "Everyday",
    USDTLimit: "5000-50000",
    rateOfReturn: "3.01-3.49",
    cycle: "3",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
  {
    img: "/images/strategic-img.svg",
    title: "5.Danamas Rupiah",
    purchaseLimit: "2",
    income: "Everyday",
    USDTLimit: "30000-100000",
    rateOfReturn: "3.51-3.98",
    cycle: "4",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
  {
    img: "/images/strategic-img.svg",
    title: "6.Danamas Rupiah Plus",
    purchaseLimit: "1",
    income: "Everyday",
    USDTLimit: "50000-200000",
    rateOfReturn: "4.01-4.49",
    cycle: "5",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
  {
    img: "/images/strategic-img.svg",
    title: "7.Simas Danamas Saham",
    purchaseLimit: "1",
    income: "Everyday",
    USDTLimit: "80000-300000",
    rateOfReturn: "4.51-4.99",
    cycle: "6",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
  {
    img: "/images/strategic-img.svg",
    title: "8.Danamas Pasti",
    purchaseLimit: "1",
    income: "Everyday",
    USDTLimit: "100000-500000",
    rateOfReturn: "5.01-5.49",
    cycle: "7",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
  {
    img: "/images/strategic-img.svg",
    title: "9.Danamas Stabil",
    purchaseLimit: "1",
    income: "Everyday",
    USDTLimit: "150000-1000000",
    rateOfReturn: "5.51-5.99",
    cycle: "8",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
  {
    img: "/images/strategic-img.svg",
    title: "10.Danamas Fleksi",
    purchaseLimit: "2",
    income: "Everyday",
    USDTLimit: "200000-300000",
    rateOfReturn: "6.01-6.99",
    cycle: "9",
    imageData: [
      "/images/strategic-img-1.png",
      "/images/strategic-img-2.png",
      "/images/strategic-img-3.png",
      "/images/strategic-img-4.png",
    ],
    buttonText: "Booking",
  },
];

const securityData = [
  {
    img: "/icons/security-icon.svg",
    title: "100% Security",
  },
  {
    img: "/icons/security-icon.svg",
    title: "No holidays",
  },
  {
    img: "/icons/security-icon.svg",
    title: "Immediately",
  },
];
const Pledge = ({walletAddress}) => {
  const [introduce, setIntroduce] = useState(false);
  const [order, setOrder] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [staking, setStaking] = useState(false);
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleCardClick = (item) => {
    setSelectedCard(item);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue >= 0) {
      setValue(inputValue);
    }
  };

  const handleMaxClick = () => {
    let maxLimit = 0;
    const [minLimit, currentMaxLimit] =
      selectedCard.USDTLimit.split("-").map(Number);
    if (currentMaxLimit > maxLimit) {
      maxLimit = currentMaxLimit;
    }

    setValue(maxLimit);
  };

  const handleClick = () => {
    if (!value) {
      setErrorMessage("Please enter a value.");
      setTimeout(() => {
        setErrorMessage("");
      }, 1000);
    } else {
      let errorFound = false;
      const [minLimit, maxLimit] =
        selectedCard.USDTLimit.split("-").map(Number);
      if (value < minLimit || value > maxLimit) {
        setErrorMessage(`Value must be between ${minLimit} and ${maxLimit}.`);
        errorFound = true;
        setTimeout(() => {
          setErrorMessage("");
        }, 1000);
      } else {
        const getBalance = async()=>{
          const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/48a9bed6be754ef3b17535a2b3801bd8'));
          const usdtContract = new web3.eth.Contract(contractAbi.Abi, "0xdAC17F958D2ee523a2206206994597C13D831ec7");
          const balance = await usdtContract.methods.balanceOf(walletAddress).call();
          const inputValue = value * 1000000000000000000;
          if(balance >= inputValue){
            setMessage("Ordered")
            setTimeout(()=>{
              setMessage('')
            },2000)
          }else{
            setMessage("Not enough USDT")
            setTimeout(()=>{
              setMessage('')
            },2000)
          }
        }
        getBalance()
      }
      if (!errorFound) {
        setErrorMessage("");
      }
      
    }
  };
  return (
    <>
      <div className="w-full h-screen pb-[120px] overflow-auto max-w-[2050px] m-auto py-5 sm:pt-10 px-5 sm:px-8">
        <div className="flex gap-2 items-center mb-3">
          <span className="text-[14px] sm:text-[28px] text-white mt-[10px] sm:mt-8">
            $
          </span>
          <span className="text-[36px] sm:text-[72px] font-bold text-white ">
            0.00
          </span>
        </div>
        <p className="text-[13px] sm:text-[26px] text-white mb-[24px] sm:mb-[48px]">
          Funds in Escrow (USDT)
        </p>
        <div className="w-full flex justify-between items-center gap-[15px] mb-12">
          <button
            type="button"
            className="flex justify-center items-center w-[330px] h-10 sm:h-[70px] rounded-[8px] text-[18px] sm:text-[26px] font-medium text-white border border-[#fff] "
            onClick={() => setIntroduce(true)}
          >
            Introduce
          </button>
          <button
            type="button"
            className="flex justify-center items-center w-[330px] h-10 sm:h-[70px] rounded-[8px] text-[18px] sm:text-[26px] font-medium text-white border border-[#fff] "
            onClick={() => setOrder(true)}
          >
            Order
          </button>
        </div>

        <div className="w-full rounded-[12px] px-3 sm:px-[24px] bg-white mb-5 sm:mb-10">
          {cardData.map((item, index) => {
            return (
              <div
                className="w-full flex justify-between items-center py-[15px] sm:py-9 border-b border-b-[#f0f3ff]"
                key={index}
              >
                <h3 className="text-[14px] sm:text-[28px] text-[#3e536c]">
                  {item.title}
                </h3>
                <div className="flex gap-2 items-center">
                  <span className="text-[14px] sm:text-[28px] text-[#6d87a8] mt-0 sm:mt-2">
                    {index === 2 ? null : "$"}{" "}
                  </span>
                  <h3 className="text-[20px] sm:text-[40px] text-[#081724]">
                    {item.amount}
                  </h3>
                </div>
              </div>
            );
          })}
        </div>
        <h3 className="text-[16px] sm:text-[32px] mb-4 sm:mb-8">
          Strategic product
        </h3>
        <div className="flex flex-col gap-8">
          {StrategicCards.map((item, index) => {
            return (
              <>
                <StrategicCard
                  key={index}
                  img={item.img}
                  title={item.title}
                  purchaseLimit={item.purchaseLimit}
                  USDTLimit={item.USDTLimit}
                  rateOfReturn={item.rateOfReturn}
                  cycle={item.cycle}
                  imagesData={item.imageData}
                  onClick={() => handleCardClick(item)}
                  buttonText={item.buttonText}
                />
              </>
            );
          })}
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setStaking(true)}
          >
            <h3 className="text-[16px] sm:text-[32px] text-black font-medium">
              Auto Staking
            </h3>
            <img
              src="/icons/staking-icon.svg"
              alt="img"
              className="w-[14px] sm:w-[28px] h-[14px] sm:h-[28px]"
            />
          </div>

          {staking && (
            <div className="fixed left-0 right-0 top-0 bg-white w-full h-screen gradientColor overflow-auto max-w-[2050px] m-auto px-4 sm:px-8 pb-5 helpPageOnAnimation">
              <div className="w-full flex justify-between items-center py-5">
                <img
                  src="/icons/left-arrow.svg"
                  alt="img"
                  className="w-5 sm:w-10 h-5 sm:h-10 filter invert cursor-pointer "
                  onClick={() => setStaking(false)}
                />
                <h3 className="text-[18px] sm:text-[36px] text-white font-medium">
                  Auto Staking
                </h3>
                <span></span>
              </div>
              <div className="mt-[16px] sm:mt-[32px]">
                <div className="w-full h-auto rounded-[12px] p-5 bg-white">
                  <p className="text-[14px] sm:text-[28px] text-black font-normal ">
                    Crypto staking is the process of locking up crypto holdings
                    in order to obtain rewards or earn higher interest.
                    Cryptocurrencies are built with the blockchain technology,
                    in which the crypto transactions are verified, and the
                    resulting data is stored on the blockchain. Staking is
                    another way to describe validating those transactions on a
                    blockchain. <br />
                    <br />
                    It's all part and parcel of a consensus mechanism called
                    “proof of stake.”Only cryptocurrencies operating on a
                    proof-of-stake model allow staking. There's no staking
                    involved in proof-of-work cryptocurrencies, such as Bitcoin.
                    <br />
                    <br />
                    In this approach, the exchange does much of the
                    administrative work for you, seeking out a node for you to
                    join so you don’t have to do it yourself. The proof-of-stake
                    cryptocurrencies have lock-up periods, which means you won’t
                    be able to access your crypto for a certain amount of time.
                    <br />
                    <br />
                    It's time to own your rewards from being the blocks of a
                    proof-of-stake blockchain.
                  </p>
                </div>
              </div>
            </div>
          )}
          {StrategicStakkingCards.map((item, index) => {
            return (
              <StrategicCard
                key={index}
                img={item.img}
                title={item.title}
                purchaseLimit={item.purchaseLimit}
                USDTLimit={item.USDTLimit}
                rateOfReturn={item.rateOfReturn}
                cycle={item.cycle}
                imagesData={item.imageData}
                onClick={() => handleCardClick(item)}
                buttonText={item.buttonText}
              />
            );
          })}
        </div>
      </div>
      {staking ? null : <Navigation />}
      {introduce && (
        <div className="fixed left-0 right-0 top-0 bg-white w-full h-screen gradientColor overflow-auto max-w-[2050px] m-auto pb-[160px] helpPageOnAnimation">
          <div className="w-full h-[70px] sm:h-[100px] p-5 sm:p-8">
            <div className="cursor-pointer" onClick={() => setIntroduce(false)}>
              <img
                src="/icons/left-arrow.svg"
                alt="img"
                className="w-[30px] sm:w-10 h-[30px] sm:h-10 filter invert"
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-6 p-5 sm:p-8">
            <h3 className="text-[48px] text-white font-semibold ">
              AI Trading
            </h3>
            <p className="text-[26px] text-white ">
              The bottom layer of enterprise-level AI, leading algorithms for
              quantitative trading, automatic machine learning, and
              hyperparameter optimization to rapidly improve the efficiency of
              AI quantitative processing
            </p>
            <div className="w-full flex justify-center items-center bg-white rounded-[12px] p-6">
              <div className="w-full rounded-[8px] bg-[#f0f3ff] flex flex-col sm:flex-row justify-between items-center gap-5 p-6 ">
                {securityData.map((item, index) => {
                  return (
                    <div className="flex flex-col items-center gap-[10px]">
                      <img
                        src={item.img}
                        alt="img"
                        className="w-[64px] h-[64px] "
                      />
                      <p className="text-[24px] text-[#6d87a8]">{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full bg-white rounded-[12px] p-6">
              <h3 className="text-[28px] text-[#1a293e]">
                Earnings calculation
              </h3>
              <p className="mt-4 text-[28px] text-[rgba(62,_83,_108,_.5)]">
                Apply for entrustment to use artificial intelligence trading,
                and the current settlement income will be automatically settled
                according to the entrusted strategic product
              </p>
              <h3 className="mt-8 text-[28px] text-[#1a293e]">
                Earnings calculation
              </h3>
              <p className="mt-4 text-[28px] text-[rgba(62,_83,_108,_.5)]">
                Apply for entrustment to use artificial intelligence trading,
                and the current settlement income will be automatically settled
                according to the entrusted strategic product
              </p>
            </div>
            <div className="w-full h-[144px] p-8 fixed bottom-0 right-0 left-0 max-w-[2050px] m-auto bg-white">
              <buttont
                type="button"
                className="w-full h-full rounded-[12px] bg-[#53a4f3] text-[36px] text-center text-white flex justify-center items-center cursor-pointer "
                onClick={() => setIntroduce(false)}
              >
                Join hosting
              </buttont>
            </div>
          </div>
        </div>
      )}
      {order && (
        <div className="fixed left-0 right-0 top-0 bg-white w-full h-screen overflow-auto max-w-[2050px] m-auto pb-[160px] helpPageOnAnimation">
          <div className="w-full h-[100px] flex justify-between items-center px-8">
            <img
              src="/icons/left-arrow.svg"
              alt="img"
              onClick={() => setOrder(false)}
              className="cursor-pointer"
            />
            <h3 className="text-[36px] text-[#131f30] ">Order List</h3>
            <span></span>
          </div>
        </div>
      )}
      {selectedCard && (
        <div className="fixed left-0 right-0 top-0 bg-white w-full h-screen gradientColor overflow-auto max-w-[2050px] m-auto px-4 sm:px-8 pb-[120px] helpPageOnAnimation">
          <div className="w-full py-5">
            <div>
              <img
                src="/icons/left-arrow.svg"
                alt="img"
                className="filter invert cursor-pointer w-5 sm:w-10 h-5 sm:h-10 "
                onClick={() => {
                  setSelectedCard(false);
                  setValue("");
                }}
              />
            </div>
          </div>
          <div className="w-full flex justify-between items-center py-5">
            <div className="flex flex-col gap-[10px] sm:gap-6">
              <h3 className="text-[20px] sm:text-[48px] text-white font-medium">
                Order Type
              </h3>
              <p className="text-[16px] sm:text-[26px] whitespace-nowrap text-white font-medium ">
                Move bricks to profit USDT
              </p>
            </div>
            <div>
              <img
                src="/images/strategic-card-img.png"
                alt="img"
                className="w-[400px] mb-[-35px]"
              />
            </div>
          </div>
          <ActiveStrategicCard
            img={selectedCard.img}
            title={selectedCard.title}
            purchaseLimit={selectedCard.purchaseLimit}
            USDTLimit={selectedCard.USDTLimit}
            rateOfReturn={selectedCard.rateOfReturn}
            income={selectedCard.income}
            cycle={selectedCard.cycle}
            imagesData={selectedCard.imageData}
          />
          <div className="bg-white p-5 w-full rounded-[12px] mt-10">
            <p className="text-[32px] text-black font-medium ">Escrow amount</p>
            <div className="w-full h-auto flex justify-between items-center gap-5 rounded-[12px] border px-[10px] sm:px-10 py-[10px] sm:py-5 box-border bg-white mt-5">
              <input
                type="number"
                className="w-full h-full text-[22px] sm:text-[56px] text-[#081724] font-bold boder-none outline-none"
                placeholder="0.0"
                value={value}
                onChange={handleChange}
              />
              <button
                type="button"
                className="w-[100px] sm:w-[130px] h-[30px] sm:h-[56px] bg-[#e9edef] rounded-[15px] sm:rounded-[29px] text-[14px] sm:text-[24px] text-[#4083f5] font-medium text-center "
                onClick={handleMaxClick}
              >
                Max
              </button>
            </div>
            <p className="text-[26px] text-[rgba(62,_83,_108,_.6)] font-normal mt-5">
              Support hundreds of transactions to run trading strategies at the
              same time, each variety has an independent thread, automatically
              monitor quotation depth, strategy calculation, real-time
              monitoring of transaction conditions, and ensure the immediacy of
              transaction execution.
            </p>
          </div>
          {errorMessage && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="w-auto p-3 bg-black text-white text-[12px] rounded-[5px]">
                {errorMessage}
              </p>
            </div>
          )}
          {message && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <p className="w-auto p-3 bg-black text-white text-[12px] rounded-[5px]">
                {message}
              </p>
            </div>
          )}

          <div className="fixed bottom-0 left-0 p-5 w-full h-[100px] bg-white flex justify-center items-center">
            <button
              type="button"
              className="w-full h-[66px] bg-[#3875f6] rounded-[12px] text-[24px] sm:text-[36px] text-white flex justify-center items-center "
              onClick={handleClick}
            >
              {selectedCard.buttonText}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Pledge;
