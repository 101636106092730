import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
const navigationIcons = [
  {
    img: "/icons/home-icon.svg",
    activeImg: "/icons/home-active.svg",
    title: "Home",
    link: "/",
  },
  {
    img: "/icons/help-icon.svg",
    activeImg: "/icons/help-active.svg",
    title: "Help",
    link: "/help",
  },
  {
    img: "/icons/pledge-icon.svg",
    activeImg: "/icons/pledge-active.svg",
    title: "Pledge",
    link: "/pledge",
  },
  {
    img: "/icons/share-icon.png",
    activeImg: "/icons/share-active.png",
    title: "Share",
    link: "/share",
  },
  {
    img: "/icons/account-icon.svg",
    activeImg: "/icons/account-active.png",
    title: "Account",
    link: "/account",
  },
];
const Navigation = () => {
  const [tab, setTab] = useState();
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <div className="flex justify-between items-center fixed bottom-0 left-0 right-0 max-w-[2050px] w-full h-[100px] p-[0px_20px] sm:p-[0px_48px] bg-white m-auto">
      {/* <img src="/icons/home-icon.svg" /> */}
      {navigationIcons.map((item, index) => {
        return (
          <Link
            key={index}
            to={item.link}
            className="flex flex-col gap-[.04rem] items-center cursor-pointer"
            onClick={() => setTab(item.link)}
          >
            <img
              src={pathName === item.link ? item.activeImg : item.img}
              alt="img"
              className="w-[25px] sm:w-auto "
            />
            <h3
              className={`${
                pathName === item.link ? "text-[#3e7dff]" : "text-[#b2b2b2]"
              } text-[12px] sm:text-[16px] `}
            >
              {item.title}
            </h3>
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
